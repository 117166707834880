<template>
  <div>
    <b-table-simple small responsive border fixed>
      <b-thead head-variant="danger">
        <b-tr>
          <b-th
            :colspan="cycleList.length > 0 ? cycleList.length : 3"
            class="text-center"
          >
            <span class="caption">
              {{
                egressProfile
                  ? egressProfileCycleSort.length > 1
                    ? String(
                        "Progresión de " +
                          $getVisibleNames(
                            "mesh.competence",
                            true,
                            "Competencias"
                          )
                      )
                    : String(
                        $getVisibleNames(
                          "mesh.competence",
                          true,
                          "Competencias"
                        )
                      )
                  : String(
                      $getVisibleNames("mesh.competence", true, "Competencias")
                    )
              }}
              {{
                egressProfile ? " de " + egressProfile.professional_title : ""
              }}
            </span>
            <br />
            <span v-if="career">{{ career.name.toUpperCase() }}</span>
            <span v-if="faculty"
              >,
              {{
                $getVisibleNames(
                  "mesh.faculty",
                  false,
                  "Facultad"
                ).toUpperCase()
              }}: {{ faculty.name.toUpperCase() }}</span
            >
          </b-th>
        </b-tr>
        <b-tr v-if="egressProfile">
          <b-th
            v-if="egressProfileCycleSort.length > 0"
            :colspan="cycleList.length > 0 ? cycleList.length : 3"
            style="
              background-color: var(--secondary-color) !important;
              color: white !important;
            "
            class="text-center"
            >{{
              cycleList.length > 1
                ? $getVisibleNames(
                    "mesh.cycle",
                    true,
                    "Ciclos Formativos"
                  ).toUpperCase()
                : $getVisibleNames(
                    "mesh.cycle",
                    false,
                    "Ciclo Formativo"
                  ).toUpperCase()
            }}</b-th
          >
        </b-tr>
        <b-tr v-if="egressProfile">
          <template v-if="egressProfileCycleSort.length > 0">
            <b-th
              variant="secondary"
              class="text-center"
              v-for="cycle in egressProfileCycleSort"
              :key="cycle.id"
              :title="cycle.description"
              >{{ cycle.name.toUpperCase() }}</b-th
            >
          </template>
          <template v-else>
            <!-- <b-th variant="secondary" colspan="3" class="text-center">
              {{
                $getVisibleNames(
                  "mesh.competence",
                  true,
                  "Competencias"
                ).toUpperCase()
              }}
            </b-th> -->
          </template>
        </b-tr>
      </b-thead>
      <b-tbody style="text-align: left">
        <template v-if="egressProfile">
          <template v-if="egressProfileCycleSort.length > 0">
            <b-tr
              v-for="(row, row_index) in competences_matrix"
              :key="row_index"
            >
              <b-td v-for="(competence, cell_index) in row" :key="cell_index">
                <div v-if="competence">
                  <template
                    v-for="competence_list in competenceList.filter(
                      (x) =>
                        x.egress_profile == competence.egress_profile &&
                        x.cycle == competence.cycle &&
                        x.order == competence.order
                    )"
                  >
                    <p
                      :id="`competence-sentence-${competence_list.id}`"
                      style="text-align: justify"
                      class="mb-4 mt-2 mx-1"
                      :key="competence_list.id"
                    >
                      <span style="position: absolute"
                        ><strong
                          >{{ competence_list.order }}.{{
                            cell_index + 1
                          }}</strong
                        ></span
                      >
                      <SentenceContainer
                        style="padding-left: 22px; padding-top: 2px"
                        :Sentence="competence_list"
                      >
                      </SentenceContainer>
                    </p>
                  </template>
                  <!-- <p
                    :id="`competence-sentence-${competence.id}`"
                    style="text-align: justify"
                    class="mb-4 mt-2 mx-1"
                  >
                    <span style="position: absolute"
                      ><strong
                        >{{ competence.order }}.{{ cell_index + 1 }}</strong
                      ></span
                    >
                    <SentenceContainer
                      style="padding-left: 14px; padding-top: 2px"
                      :Sentence="competence"
                    >
                    </SentenceContainer>
                  </p> -->
                  <b-tooltip
                    v-if="competenceCognitiveLevel(cell_index, row)"
                    :target="`competence-sentence-${competence.id}`"
                    variant="secondary"
                    style="z-index: 100"
                  >
                    <b-icon icon="exclamation-circle"></b-icon>
                    <span class="cognitive-level-tooltip">
                      <!-- competencia -->
                      La
                      {{
                        $getVisibleNames(
                          "mesh.competence",
                          false,
                          "Competencia"
                        )
                      }}
                      pertenece a un
                      {{
                        $getVisibleNames(
                          "mesh.cognitivelevel",
                          false,
                          "Nivel Cognitivo"
                        ).toLowerCase()
                      }}
                      superior al que se encuentra en el siguiente
                      {{
                        $getVisibleNames(
                          "mesh.cycle",
                          false,
                          "Ciclo Formativo"
                        )
                      }}.
                    </span>
                  </b-tooltip>
                  <div class="float-button">
                    <div class="d-flex">
                      <button-add
                        v-can="'mesh.add_egressprofilecompetence'"
                        v-if="
                          isNaN(competence.competence) &&
                          allows_crud &&
                          ((user_position &&
                            [1, 2, 3].includes(user_position.position) &&
                            [1, 2, 3].includes(user.groups[0])) ||
                            user.is_superuser ||
                            $hasObjectPermission(
                              'mesh.career',
                              'change',
                              egressProfile.career
                            ))
                        "
                        @click="askForUpdateCompetence(competence)"
                      ></button-add>
                      <template
                        v-if="
                          (institution && institution.show_base_ra) ||
                          $debug_change_duoc
                        "
                      >
                        <div v-if="!isNaN(competence.competence)" class="mr-1">
                          <h5
                            :key="`ra-base-header-${competence.id}`"
                            :id="`ra-base-header-${competence.id}`"
                            v-bind:class="{
                              'pointer-class':
                                allows_crud &&
                                ((user_position &&
                                  [1, 2, 3].includes(user_position.position) &&
                                  [1, 2, 3].includes(user.groups[0])) ||
                                  user.is_superuser ||
                                  $hasObjectPermission(
                                    'mesh.career',
                                    'change',
                                    egressProfile.career
                                  )),
                            }"
                            @click="
                              () => {
                                if (
                                  allows_crud &&
                                  ((user_position &&
                                    [1, 2, 3].includes(
                                      user_position.position
                                    ) &&
                                    [1, 2, 3].includes(user.groups[0])) ||
                                    user.is_superuser ||
                                    $hasObjectPermission(
                                      'mesh.career',
                                      'change',
                                      egressProfile.career
                                    ))
                                )
                                  $bvModal.show(
                                    `modal-base-ra-${competence.id}`
                                  );
                              }
                            "
                          >
                            <b-badge class="class-badge" variant="secondary">{{
                              raBasesFiltered(competence.competence).length
                            }}</b-badge>
                          </h5>
                          <b-modal
                            :id="`modal-base-ra-${competence.id}`"
                            hide-footer
                            :title="
                              $getVisibleNames(
                                'teaching.basera',
                                false,
                                'RA Base'
                              )
                            "
                            size="lg"
                          >
                            <RaBase
                              :competence_id="competence.competence"
                            ></RaBase>
                          </b-modal>
                          <b-popover
                            :target="`ra-base-header-${competence.id}`"
                            :key="`ra-base-tooltip-${competence.id}`"
                            triggers="hover"
                          >
                            <template #title>{{
                              $getVisibleNames(
                                "teaching.basera",
                                false,
                                "RA Base"
                              )
                            }}</template>
                            <template
                              v-if="
                                raBasesFiltered(competence.competence).length >
                                0
                              "
                            >
                              <div class="ra-competences-container">
                                <span
                                  v-for="ra in raBasesFiltered(
                                    competence.competence
                                  )"
                                  :key="ra.id"
                                >
                                  <SentenceContainer :Sentence="ra">
                                  </SentenceContainer>
                                </span>
                              </div>
                            </template>
                            <template v-else
                              >No cuenta con ningún
                              {{
                                $getVisibleNames(
                                  "teaching.basera",
                                  false,
                                  "RA Base"
                                )
                              }}
                              creado
                            </template>
                          </b-popover>
                        </div>
                      </template>
                      <!-- && competence.can_edit == user.id -->
                      <button-edit
                        v-can="'mesh.change_egressprofilecompetence'"
                        v-if="
                          !isNaN(competence.competence) &&
                          allows_crud &&
                          ((user_position &&
                            [1, 2, 3].includes(user_position.position) &&
                            [1, 2, 3].includes(user.groups[0])) ||
                            user.is_superuser ||
                            $hasObjectPermission(
                              'mesh.career',
                              'change',
                              egressProfile.career
                            ))
                        "
                        @click="askForUpdateCompetence(competence)"
                      ></button-edit>
                      <button-delete
                        v-can="'mesh.delete_egressprofilecompetence'"
                        v-if="
                          !isNaN(competence.id) &&
                          cell_index < row.length - 1 &&
                          allows_crud &&
                          ((user_position &&
                            [1, 2, 3].includes(user_position.position) &&
                            [1, 2, 3].includes(user.groups[0])) ||
                            user.is_superuser ||
                            $hasObjectPermission(
                              'mesh.career',
                              'change',
                              egressProfile.career
                            ))
                        "
                        @click="askForDeleteCompetence(competence.id)"
                      ></button-delete>
                    </div>
                  </div>
                </div>
                <div v-else>-</div>
              </b-td>
            </b-tr>
            <!-- COMPETENCIAS SIN CICLOS -->
            <template
              v-if="competenceList.filter((x) => x.cycle == null).length > 0"
            >
              <b-tr>
                <b-th :colspan="cycleList.length > 0 ? cycleList.length : 3">
                  <div class="text-center">
                    {{
                      $getVisibleNames(
                        "mesh.competence",
                        false,
                        "Competencia"
                      ).toUpperCase()
                    }}
                    SIN
                    {{
                      $getVisibleNames(
                        "mesh.cycle",
                        true,
                        "Ciclos Formativos"
                      ).toUpperCase()
                    }}
                    <InfoTooltip
                      class="noprint info-icon"
                      :tooltip_text="`Estas ${$getVisibleNames(
                        'mesh.competence',
                        true,
                        'Competencias'
                      )} son irregulares en el ${$getVisibleNames(
                        'mesh.egressprofile',
                        false,
                        'Perfil de Egreso'
                      )}, puede cambiarles el ${$getVisibleNames(
                        'mesh.cycle',
                        false,
                        'Ciclo Formativo'
                      )} o eliminarlas; para que no afecten a la configuración de la ${$getVisibleNames(
                        'manual.matriz_3',
                        false,
                        'Matriz 3'
                      )}.`"
                    >
                    </InfoTooltip>
                  </div>
                </b-th>
              </b-tr>
              <b-tr
                v-for="competence_type in competenceTypes"
                :key="competence_type.id"
              >
                <b-td
                  :colspan="cycleList.length > 0 ? cycleList.length : 3"
                  rowspan="1"
                  v-if="
                    competenceList.filter(
                      (x) => x.type == competence_type.id && x.cycle == null
                    ).length > 0
                  "
                >
                  <div
                    style="
                      background-color: var(--secondary-color);
                      color: white;
                    "
                    class="text-center"
                  >
                    <strong :title="competence_type.description">{{
                      competence_type.name.toUpperCase()
                    }}</strong>
                  </div>
                  <div
                    class="pl-3"
                    v-for="competence in competenceList.filter(
                      (x) => x.type == competence_type.id && x.cycle == null
                    )"
                    :key="competence.id"
                  >
                    <b-row style="width: 100%; height: 100%">
                      <b-col>
                        <p>
                          <span style="position: absolute">{{
                            competence.order
                          }}</span>
                          <SentenceContainer
                            style="padding-left: 10px"
                            :Sentence="competence"
                          >
                          </SentenceContainer>
                        </p>
                      </b-col>
                      <b-col cols="1">
                        <div class="button-wrap">
                          <div
                            class="mr-1"
                            v-if="
                              (institution && institution.show_base_ra) ||
                              $debug_change_duoc
                            "
                          >
                            <h5
                              :key="`ra-base-header-${competence.id}`"
                              :id="`ra-base-header-${competence.id}`"
                              v-bind:class="{
                                'pointer-class':
                                  allows_crud &&
                                  ((user_position &&
                                    [1, 2, 3].includes(
                                      user_position.position
                                    ) &&
                                    [1, 2, 3].includes(user.groups[0])) ||
                                    user.is_superuser ||
                                    $hasObjectPermission(
                                      'mesh.career',
                                      'change',
                                      egressProfile.career
                                    )),
                              }"
                              @click="
                                () => {
                                  if (
                                    allows_crud &&
                                    ((user_position &&
                                      [1, 2, 3].includes(
                                        user_position.position
                                      ) &&
                                      [1, 2, 3].includes(user.groups[0])) ||
                                      user.is_superuser ||
                                      $hasObjectPermission(
                                        'mesh.career',
                                        'change',
                                        egressProfile.career
                                      ))
                                  )
                                    $bvModal.show(
                                      `modal-base-ra-${competence.id}`
                                    );
                                }
                              "
                            >
                              <b-badge
                                class="class-badge"
                                variant="secondary"
                                >{{
                                  raBasesFiltered(competence.competence).length
                                }}</b-badge
                              >
                            </h5>
                            <b-modal
                              :id="`modal-base-ra-${competence.id}`"
                              hide-footer
                              :title="
                                $getVisibleNames(
                                  'teaching.basera',
                                  false,
                                  'RA Base'
                                )
                              "
                              size="lg"
                            >
                              <RaBase
                                :competence_id="competence.competence"
                              ></RaBase>
                            </b-modal>
                            <b-popover
                              :target="`ra-base-header-${competence.id}`"
                              :key="`ra-base-tooltip-${competence.id}`"
                              triggers="hover"
                            >
                              <template #title>{{
                                $getVisibleNames(
                                  "teaching.basera",
                                  false,
                                  "RA Base"
                                )
                              }}</template>
                              <template
                                v-if="
                                  raBasesFiltered(competence.competence)
                                    .length > 0
                                "
                              >
                                <div class="ra-competences-container">
                                  <span
                                    v-for="ra in raBasesFiltered(
                                      competence.competence
                                    )"
                                    :key="ra.id"
                                  >
                                    <SentenceContainer :Sentence="ra">
                                    </SentenceContainer>
                                  </span>
                                </div>
                              </template>
                              <template v-else
                                >No cuenta con ningún
                                {{
                                  $getVisibleNames(
                                    "teaching.basera",
                                    false,
                                    "RA Base"
                                  )
                                }}
                                creado
                              </template>
                            </b-popover>
                          </div>
                          <button-edit
                            v-if="
                              allows_crud &&
                              ((user_position &&
                                [1, 2, 3].includes(user_position.position) &&
                                [1, 2, 3].includes(user.groups[0])) ||
                                user.is_superuser ||
                                $hasObjectPermission(
                                  'mesh.career',
                                  'change',
                                  egressProfile.career
                                ))
                            "
                            v-can="'mesh.change_egressprofilecompetence'"
                            @click="openModalCompetenceForm(competence.id)"
                          ></button-edit>
                          <button-delete
                            v-if="
                              allows_crud &&
                              ((user_position &&
                                [1, 2, 3].includes(user_position.position) &&
                                [1, 2, 3].includes(user.groups[0])) ||
                                user.is_superuser ||
                                $hasObjectPermission(
                                  'mesh.career',
                                  'change',
                                  egressProfile.career
                                ))
                            "
                            v-can="'mesh.delete_egressprofilecompetence'"
                            @click="askForDeleteCompetence(competence.id)"
                          ></button-delete>
                          <b-modal
                            :id="`modal-edit-competence-${competence.id}`"
                            :title="`Modificar ${$getVisibleNames(
                              'mesh.competence',
                              false,
                              'Competencia'
                            )}`"
                            size="lg"
                            hide-footer
                            no-enforce-focus
                            @hide="hideModalCompetenceForm"
                          >
                            <CompetenceForm
                              :Competence="
                                competences.find(
                                  (x) => x.id == competence.competence
                                )
                              "
                              :egress_profile_id="egress_profile_id"
                              :type_id="competence.type"
                              :cycle_id="competence.cycle"
                              @updated="
                                $bvModal.hide(
                                  `modal-edit-competence-${competence.id}`
                                )
                              "
                              @deleted="
                                $bvModal.hide(
                                  `modal-edit-competence-${competence.id}`
                                )
                              "
                              :egress_profile_view="true"
                              :can_edit_cycle="false"
                              :can_edit_profile_cycle="true"
                              :can_edit_type="true"
                              :allows_crud="true"
                              :show_cycle="false"
                            ></CompetenceForm>
                          </b-modal>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td
                  :colspan="cycleList.length > 0 ? cycleList.length : 3"
                  rowspan="1"
                  v-if="
                    competenceList.filter(
                      (x) => x.type == null && x.cycle == null
                    ).length > 0
                  "
                >
                  <div
                    style="
                      background-color: var(--secondary-color);
                      color: white;
                    "
                    class="text-center"
                  >
                    <strong>
                      {{
                        $getVisibleNames(
                          "mesh.competence",
                          true,
                          "Competencias"
                        ).toUpperCase()
                      }}
                    </strong>
                  </div>
                  <div
                    class="pl-3"
                    v-for="competence in competenceList.filter(
                      (x) => x.type == null && x.cycle == null
                    )"
                    :key="competence.id"
                  >
                    <b-row style="width: 100%; height: 100%">
                      <b-col>
                        <p>
                          <span style="position: absolute">{{
                            competence.order
                          }}</span>
                          <SentenceContainer
                            style="padding-left: 10px"
                            :Sentence="competence"
                          >
                          </SentenceContainer>
                        </p>
                      </b-col>
                      <b-col cols="1">
                        <div class="button-wrap">
                          <!-- v-if="competence.can_edit == user.id" -->
                          <div
                            class="mr-1"
                            v-if="
                              (institution && institution.show_base_ra) ||
                              $debug_change_duoc
                            "
                          >
                            <h5
                              :key="`ra-base-header-${competence.id}`"
                              :id="`ra-base-header-${competence.id}`"
                              v-bind:class="{
                                'pointer-class':
                                  allows_crud &&
                                  ((user_position &&
                                    [1, 2, 3].includes(
                                      user_position.position
                                    ) &&
                                    [1, 2, 3].includes(user.groups[0])) ||
                                    user.is_superuser ||
                                    $hasObjectPermission(
                                      'mesh.career',
                                      'change',
                                      egressProfile.career
                                    )),
                              }"
                              @click="
                                () => {
                                  if (
                                    allows_crud &&
                                    ((user_position &&
                                      [1, 2, 3].includes(
                                        user_position.position
                                      ) &&
                                      [1, 2, 3].includes(user.groups[0])) ||
                                      user.is_superuser ||
                                      $hasObjectPermission(
                                        'mesh.career',
                                        'change',
                                        egressProfile.career
                                      ))
                                  )
                                    $bvModal.show(
                                      `modal-base-ra-${competence.id}`
                                    );
                                }
                              "
                            >
                              <b-badge
                                class="class-badge"
                                variant="secondary"
                                >{{
                                  raBasesFiltered(competence.competence).length
                                }}</b-badge
                              >
                            </h5>
                            <b-modal
                              :id="`modal-base-ra-${competence.id}`"
                              hide-footer
                              :title="
                                $getVisibleNames(
                                  'teaching.basera',
                                  false,
                                  'RA Base'
                                )
                              "
                              size="lg"
                            >
                              <RaBase
                                :competence_id="competence.competence"
                              ></RaBase>
                            </b-modal>
                            <b-popover
                              :target="`ra-base-header-${competence.id}`"
                              :key="`ra-base-tooltip-${competence.id}`"
                              triggers="hover"
                            >
                              <template #title>{{
                                $getVisibleNames(
                                  "teaching.basera",
                                  false,
                                  "RA Base"
                                )
                              }}</template>
                              <template
                                v-if="
                                  raBasesFiltered(competence.competence)
                                    .length > 0
                                "
                              >
                                <div class="ra-competences-container">
                                  <span
                                    v-for="ra in raBasesFiltered(
                                      competence.competence
                                    )"
                                    :key="ra.id"
                                  >
                                    <SentenceContainer :Sentence="ra">
                                    </SentenceContainer>
                                  </span>
                                </div>
                              </template>
                              <template v-else
                                >No cuenta con ningún
                                {{
                                  $getVisibleNames(
                                    "teaching.basera",
                                    false,
                                    "RA Base"
                                  )
                                }}
                                creado
                              </template>
                            </b-popover>
                          </div>
                          <button-edit
                            v-if="
                              allows_crud &&
                              ((user_position &&
                                [1, 2, 3].includes(user_position.position) &&
                                [1, 2, 3].includes(user.groups[0])) ||
                                user.is_superuser ||
                                $hasObjectPermission(
                                  'mesh.career',
                                  'change',
                                  egressProfile.career
                                ))
                            "
                            v-can="'mesh.change_egressprofilecompetence'"
                            @click="openModalCompetenceForm(competence.id)"
                          ></button-edit>
                          <button-delete
                            v-if="
                              allows_crud &&
                              ((user_position &&
                                [1, 2, 3].includes(user_position.position) &&
                                [1, 2, 3].includes(user.groups[0])) ||
                                user.is_superuser ||
                                $hasObjectPermission(
                                  'mesh.career',
                                  'change',
                                  egressProfile.career
                                ))
                            "
                            v-can="'mesh.delete_egressprofilecompetence'"
                            @click="askForDeleteCompetence(competence.id)"
                          ></button-delete>
                          <b-modal
                            :id="`modal-edit-competence-${competence.id}`"
                            :title="`Modificar ${$getVisibleNames(
                              'mesh.competence',
                              false,
                              'Competencia'
                            )}`"
                            size="lg"
                            hide-footer
                            no-enforce-focus
                            @hide="hideModalCompetenceForm"
                          >
                            <CompetenceForm
                              :Competence="
                                competences.find(
                                  (x) => x.id == competence.competence
                                )
                              "
                              :egress_profile_id="egress_profile_id"
                              :type_id="competence.type"
                              :cycle_id="competence.cycle"
                              @updated="
                                $bvModal.hide(
                                  `modal-edit-competence-${competence.id}`
                                )
                              "
                              @deleted="
                                $bvModal.hide(
                                  `modal-edit-competence-${competence.id}`
                                )
                              "
                              :can_edit_profile_cycle="true"
                              :egress_profile_view="true"
                              :can_edit_cycle="false"
                              :can_edit_type="true"
                              :allows_crud="true"
                              :show_cycle="false"
                            ></CompetenceForm>
                          </b-modal>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-td>
              </b-tr>
            </template>
          </template>
          <template v-else>
            <tr
              v-for="competence_type in competenceTypes"
              :key="competence_type.id"
            >
              <td
                colspan="3"
                rowspan="1"
                v-if="
                  competences_matrix.filter((x) => x.type == competence_type.id)
                    .length > 0
                "
              >
                <div
                  style="background-color: var(--secondary-color); color: white"
                  class="text-center"
                >
                  <strong :title="competence_type.description">{{
                    competence_type.name.toUpperCase()
                  }}</strong>
                </div>
                <div
                  class="pl-3"
                  v-for="competence in competences_matrix.filter(
                    (x) => x.type == competence_type.id
                  )"
                  :key="competence.id"
                >
                  <b-row style="width: 100%; height: 100%">
                    <b-col>
                      <p>
                        <span style="position: absolute">{{
                          competence.order
                        }}</span>
                        <SentenceContainer
                          style="padding-left: 10px"
                          :Sentence="competence"
                        >
                        </SentenceContainer>
                      </p>
                    </b-col>
                    <b-col cols="1">
                      <div class="button-wrap">
                        <!-- v-if="competence.can_edit == user.id" -->
                        <div
                          class="mr-1"
                          v-if="
                            (institution && institution.show_base_ra) ||
                            $debug_change_duoc
                          "
                        >
                          <h5
                            :key="`ra-base-header-${competence.id}`"
                            :id="`ra-base-header-${competence.id}`"
                            v-bind:class="{
                              'pointer-class':
                                allows_crud &&
                                ((user_position &&
                                  [1, 2, 3].includes(user_position.position) &&
                                  [1, 2, 3].includes(user.groups[0])) ||
                                  user.is_superuser ||
                                  $hasObjectPermission(
                                    'mesh.career',
                                    'change',
                                    egressProfile.career
                                  )),
                            }"
                            @click="
                              () => {
                                if (
                                  allows_crud &&
                                  ((user_position &&
                                    [1, 2, 3].includes(
                                      user_position.position
                                    ) &&
                                    [1, 2, 3].includes(user.groups[0])) ||
                                    user.is_superuser ||
                                    $hasObjectPermission(
                                      'mesh.career',
                                      'change',
                                      egressProfile.career
                                    ))
                                )
                                  $bvModal.show(
                                    `modal-base-ra-${competence.id}`
                                  );
                              }
                            "
                          >
                            <b-badge class="class-badge" variant="secondary">{{
                              raBasesFiltered(competence.competence).length
                            }}</b-badge>
                          </h5>
                          <b-modal
                            :id="`modal-base-ra-${competence.id}`"
                            hide-footer
                            :title="
                              $getVisibleNames(
                                'teaching.basera',
                                false,
                                'RA Base'
                              )
                            "
                            size="lg"
                          >
                            <RaBase
                              :competence_id="competence.competence"
                            ></RaBase>
                          </b-modal>
                          <b-popover
                            :target="`ra-base-header-${competence.id}`"
                            :key="`ra-base-tooltip-${competence.id}`"
                            triggers="hover"
                          >
                            <template #title>{{
                              $getVisibleNames(
                                "teaching.basera",
                                false,
                                "RA Base"
                              )
                            }}</template>
                            <template
                              v-if="
                                raBasesFiltered(competence.competence).length >
                                0
                              "
                            >
                              <div class="ra-competences-container">
                                <span
                                  v-for="ra in raBasesFiltered(
                                    competence.competence
                                  )"
                                  :key="ra.id"
                                >
                                  <SentenceContainer :Sentence="ra">
                                  </SentenceContainer>
                                </span>
                              </div>
                            </template>
                            <template v-else
                              >No cuenta con ningún
                              {{
                                $getVisibleNames(
                                  "teaching.basera",
                                  false,
                                  "RA Base"
                                )
                              }}
                              creado
                            </template>
                          </b-popover>
                        </div>
                        <button-edit
                          v-if="
                            allows_crud &&
                            ((user_position &&
                              [1, 2, 3].includes(user_position.position) &&
                              [1, 2, 3].includes(user.groups[0])) ||
                              user.is_superuser ||
                              $hasObjectPermission(
                                'mesh.career',
                                'change',
                                egressProfile.career
                              ))
                          "
                          v-can="'mesh.change_egressprofilecompetence'"
                          @click="openModalCompetenceForm(competence.id)"
                        ></button-edit>
                        <button-delete
                          v-if="
                            allows_crud &&
                            ((user_position &&
                              [1, 2, 3].includes(user_position.position) &&
                              [1, 2, 3].includes(user.groups[0])) ||
                              user.is_superuser ||
                              $hasObjectPermission(
                                'mesh.career',
                                'change',
                                egressProfile.career
                              ))
                          "
                          v-can="'mesh.delete_egressprofilecompetence'"
                          @click="askForDeleteCompetence(competence.id)"
                        ></button-delete>
                        <b-modal
                          :id="`modal-edit-competence-${competence.id}`"
                          :title="`Modificar ${$getVisibleNames(
                            'mesh.competence',
                            false,
                            'Competencia'
                          )}`"
                          size="lg"
                          hide-footer
                          no-enforce-focus
                          @hide="hideModalCompetenceForm"
                        >
                          <CompetenceForm
                            :Competence="
                              competences.find(
                                (x) => x.id == competence.competence
                              )
                            "
                            :egress_profile_id="egress_profile_id"
                            :type_id="competence.type"
                            :cycle_id="competence.cycle"
                            @updated="
                              $bvModal.hide(
                                `modal-edit-competence-${competence.id}`
                              )
                            "
                            @deleted="
                              $bvModal.hide(
                                `modal-edit-competence-${competence.id}`
                              )
                            "
                            :egress_profile_view="true"
                            :can_edit_cycle="false"
                            :can_edit_type="true"
                            :allows_crud="true"
                            :show_cycle="false"
                          ></CompetenceForm>
                        </b-modal>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </td>
            </tr>
            <tr>
              <td
                colspan="3"
                rowspan="1"
                v-if="
                  competences_matrix.filter((x) => x.type == null).length > 0
                "
              >
                <div
                  style="background-color: var(--secondary-color); color: white"
                  class="text-center"
                >
                  <strong>{{
                    $getVisibleNames(
                      "mesh.competence",
                      true,
                      "Competencias"
                    ).toUpperCase()
                  }}</strong>
                </div>
                <div
                  class="pl-3"
                  v-for="competence in competences_matrix.filter(
                    (x) => x.type == null
                  )"
                  :key="competence.id"
                >
                  <b-row style="width: 100%; height: 100%">
                    <b-col>
                      <p>
                        <span style="position: absolute">{{
                          competence.order
                        }}</span>
                        <SentenceContainer
                          style="padding-left: 10px"
                          :Sentence="competence"
                        >
                        </SentenceContainer>
                      </p>
                    </b-col>
                    <b-col cols="1">
                      <div class="button-wrap">
                        <!-- v-if="competence.can_edit == user.id" -->
                        <div
                          class="mr-1"
                          v-if="
                            (institution && institution.show_base_ra) ||
                            $debug_change_duoc
                          "
                        >
                          <h5
                            :key="`ra-base-header-${competence.id}`"
                            :id="`ra-base-header-${competence.id}`"
                            v-bind:class="{
                              'pointer-class':
                                allows_crud &&
                                ((user_position &&
                                  [1, 2, 3].includes(user_position.position) &&
                                  [1, 2, 3].includes(user.groups[0])) ||
                                  user.is_superuser ||
                                  $hasObjectPermission(
                                    'mesh.career',
                                    'change',
                                    egressProfile.career
                                  )),
                            }"
                            @click="
                              () => {
                                if (
                                  allows_crud &&
                                  ((user_position &&
                                    [1, 2, 3].includes(
                                      user_position.position
                                    ) &&
                                    [1, 2, 3].includes(user.groups[0])) ||
                                    user.is_superuser ||
                                    $hasObjectPermission(
                                      'mesh.career',
                                      'change',
                                      egressProfile.career
                                    ))
                                )
                                  $bvModal.show(
                                    `modal-base-ra-${competence.id}`
                                  );
                              }
                            "
                          >
                            <b-badge class="class-badge" variant="secondary">{{
                              raBasesFiltered(competence.competence).length
                            }}</b-badge>
                          </h5>
                          <b-modal
                            :id="`modal-base-ra-${competence.id}`"
                            hide-footer
                            :title="
                              $getVisibleNames(
                                'teaching.basera',
                                false,
                                'RA Base'
                              )
                            "
                            size="lg"
                          >
                            <RaBase
                              :competence_id="competence.competence"
                            ></RaBase>
                          </b-modal>
                          <b-popover
                            :target="`ra-base-header-${competence.id}`"
                            :key="`ra-base-tooltip-${competence.id}`"
                            triggers="hover"
                          >
                            <template #title>{{
                              $getVisibleNames(
                                "teaching.basera",
                                false,
                                "RA Base"
                              )
                            }}</template>
                            <template
                              v-if="
                                raBasesFiltered(competence.competence).length >
                                0
                              "
                            >
                              <div class="ra-competences-container">
                                <span
                                  v-for="ra in raBasesFiltered(
                                    competence.competence
                                  )"
                                  :key="ra.id"
                                >
                                  <SentenceContainer
                                    :unique_id="
                                      competence.competence +
                                      '-' +
                                      ra.id +
                                      '-' +
                                      ra.full_sentence
                                    "
                                    :Sentence="ra"
                                  >
                                  </SentenceContainer>
                                </span>
                              </div>
                            </template>
                            <template v-else
                              >No cuenta con ningún
                              {{
                                $getVisibleNames(
                                  "teaching.basera",
                                  false,
                                  "RA Base"
                                )
                              }}
                              creado
                            </template>
                          </b-popover>
                        </div>
                        <button-edit
                          v-if="
                            allows_crud &&
                            ((user_position &&
                              [1, 2, 3].includes(user_position.position) &&
                              [1, 2, 3].includes(user.groups[0])) ||
                              user.is_superuser ||
                              $hasObjectPermission(
                                'mesh.career',
                                'change',
                                egressProfile.career
                              ))
                          "
                          v-can="'mesh.change_egressprofilecompetence'"
                          @click="openModalCompetenceForm(competence.id)"
                        ></button-edit>
                        <button-delete
                          v-if="
                            allows_crud &&
                            ((user_position &&
                              [1, 2, 3].includes(user_position.position) &&
                              [1, 2, 3].includes(user.groups[0])) ||
                              user.is_superuser ||
                              $hasObjectPermission(
                                'mesh.career',
                                'change',
                                egressProfile.career
                              ))
                          "
                          v-can="'mesh.delete_egressprofilecompetence'"
                          @click="askForDeleteCompetence(competence.id)"
                        ></button-delete>
                        <b-modal
                          :id="`modal-edit-competence-${competence.id}`"
                          :title="`Modificar ${$getVisibleNames(
                            'mesh.competence',
                            false,
                            'Competencia'
                          )}`"
                          size="lg"
                          hide-footer
                          no-enforce-focus
                          @hide="hideModalCompetenceForm"
                        >
                          <CompetenceForm
                            :Competence="
                              competences.find(
                                (x) => x.id == competence.competence
                              )
                            "
                            :egress_profile_id="egress_profile_id"
                            :type_id="competence.type"
                            :cycle_id="competence.cycle"
                            @updated="
                              $bvModal.hide(
                                `modal-edit-competence-${competence.id}`
                              )
                            "
                            @deleted="
                              $bvModal.hide(
                                `modal-edit-competence-${competence.id}`
                              )
                            "
                            :egress_profile_view="true"
                            :can_edit_cycle="false"
                            :can_edit_type="true"
                            :allows_crud="true"
                            :show_cycle="false"
                          ></CompetenceForm>
                        </b-modal>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </b-tbody>
    </b-table-simple>
    <div class="empty-text" v-if="competenceList.length == 0">
      No hay
      {{ $getVisibleNames("mesh.competence", true, "Competencias") }} asociadas
      a este perfil.
    </div>

    <div>
      <b-modal
        id="modal"
        :title="`Redactar ${$getVisibleNames(
          'mesh.competence',
          false,
          'Competencia'
        )}`"
        size="lg"
        :hide-footer="true"
        ref="competence_modal"
        no-enforce-focus
      >
        <CompetenceForm
          v-if="competence_in_proccess"
          :Competence="
            !isNaN(competence_in_proccess.id)
              ? competences.find(
                  (x) => x.id == competence_in_proccess.competence
                )
              : null
          "
          :type_id="competence_in_proccess.type"
          :cycle_id="competence_in_proccess.cycle"
          :order="competence_in_proccess.order"
          :egress_profile_id="egress_profile_id"
          @updated="slotUpdatedCompetence"
          @created="slotUpdatedCompetence"
          @deleted="$bvModal.hide('modal')"
          :egress_profile_view="true"
          :can_edit_type="true"
          :can_edit_cycle="false"
          :can_edit_profile_cycle="true"
          :can_edit_order="true"
          :allows_crud="
            allows_crud &&
            ((user_position &&
              [1, 2, 3].includes(user_position.position) &&
              [1, 2, 3].includes(user.groups[0])) ||
              user.is_superuser ||
              $hasObjectPermission(
                'mesh.career',
                'change',
                egressProfile.career
              ))
          "
          :matrix1_competence="true"
        ></CompetenceForm>
      </b-modal>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId, toast } from "@/utils/utils";

export default {
  name: "Matrix1",
  components: {
    RaBase: () => import("@/components/reusable/RaBase"),
    CompetenceForm: () =>
      import("../CurricularResources/Competence/CompetenceForm"),
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    InfoTooltip: () => import("@/components/reusable/InfoTooltip"),
  },
  props: {
    egress_profile_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      competences_matrix: [],
      competence_in_proccess: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      user_position: names.USER_POSITION,
      institution: "getInstitution",
      egressProfiles: names.EGRESS_PROFILES,
      careers: names.CAREERS,
      faculties: names.FACULTIES,
      ra_bases: names.RA_BASES,
      competenceTypes: names.COMPETENCE_TYPES,
      profile_competences: names.PROFILE_COMPETENCES,
      profile_cycles: names.PROFILE_CYCLES,
      cycles: names.CYCLES,
      competences: names.COMPETENCES,
      taxonomy: names.TAXONOMY,
    }),
    egressProfile() {
      return this.egressProfiles.find((x) => x.id == this.egress_profile_id);
    },
    career() {
      if (!this.egressProfile) return null;
      return this.careers.find((x) => x.id == this.egressProfile.career);
    },
    faculty() {
      if (!this.career) return null;
      return this.faculties.find((x) => x.id == this.career.faculty);
    },
    egressProfileCycleId() {
      if (!this.egress_profile_id) return [];
      let list = [];
      this.profile_cycles.forEach((x) => {
        if (x.egress_profile == this.egress_profile_id) list.push(x.cycle);
      });
      return list;
    },
    egressProfileCycleSort() {
      if (!this.egressProfile) return [];
      let cycles = this.cycles.filter((x) =>
        this.egressProfileCycleId.includes(x.id)
      );
      cycles.forEach((x) => {
        let cycle_list = this.profile_cycles.find(
          (profile_cycle) =>
            profile_cycle.cycle == x.id &&
            profile_cycle.egress_profile == this.egress_profile_id
        );
        if (cycle_list) {
          x.init_semester = cycle_list.init_semester;
          x.end_semester = cycle_list.end_semester;
          x.order = cycle_list.order;
        }
      });
      return cycles.sort(function (a, b) {
        if (a.init_semester < b.init_semester) return -1;
        if (a.init_semester > b.init_semester) return 1;
        if (a.end_semester < b.end_semester) return -1;
        if (a.end_semester > b.end_semester) return 1;
        return 0;
      });
    },
    // competenceList() {
    //   return this.competences.filter(
    //     (x) => x.egress_profile == this.egress_profile_id
    //   );
    // },
    competenceList() {
      let list = [];
      if (!this.egressProfile) return [];
      let competences = [];
      if (this.egressProfileCycleSort.length > 0) {
        competences = this.profile_competences.filter(
          (x) =>
            (x.egress_profile == this.egress_profile_id &&
              this.egressProfileCycleId.includes(x.cycle)) ||
            (x.egress_profile == this.egress_profile_id && x.cycle == null)
        );
      } else {
        competences = this.profile_competences.filter(
          (x) => x.egress_profile == this.egress_profile_id && x.cycle == null
        );
      }
      competences.forEach((competence) => {
        if (competence) {
          let get_competence = this.competences.find(
            (x) => x.id == competence.competence
          );
          if (get_competence && this.taxonomy) {
            let get_verb = null;
            this.taxonomy.cognitive_levels.forEach((cl) => {
              if (cl.verbs.find((x) => x.id == get_competence.verb)) {
                get_verb = cl.verbs.find((x) => x.id == get_competence.verb);
              }
            });
            list.push({
              id: competence.id,
              competence: competence.competence,
              order: competence.order,
              egress_profile: competence.egress_profile,
              cycle: competence.cycle,
              action: get_competence.action,
              content: get_competence.content,
              condition: get_competence.condition,
              context: get_competence.context,
              full_sentence: get_competence.full_sentence,
              // can_edit: get_competence.created_by,
              type: get_competence.type,
              verb: get_competence.verb,
              sentence: get_competence.sentence,
              name: get_competence.name,
              cognitive_level: get_verb ? get_verb.cognitive_level : 0,
            });
          }
        }
      });
      return list.sort(function (a, b) {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });
    },
    cycleList() {
      let list = [];
      if (!this.egressProfile) return list;
      this.egressProfileCycleId.forEach((cycle_id) => {
        const cycle = this.cycles.find((x) => x.id == cycle_id);
        if (cycle) list.push(cycle);
      });
      return list;
    },
  },
  methods: {
    openModalCompetenceForm(competence_id) {
      this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
      this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);

      this.$nextTick(() => {
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            egress_profile_id: this.egress_profile_id,
            intelligence_option: 1,
          });
          this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
            visible_intelligence_options: [1],
            disabled_selected_egress_profile: true,
          });
      });
      this.$bvModal.show(`modal-edit-competence-${competence_id}`);
    },
    hideModalCompetenceForm() {
      this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
      this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);

      this.$nextTick(() => {
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            egress_profile_id: this.egress_profile_id,
            intelligence_option: 1,
          });
          this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
            visible_intelligence_options: [1],
            disabled_selected_egress_profile: true,
          });
      });
    },
    slotUpdatedCompetence(item) {
      this.competence_in_proccess = item;
      this.$refs.competence_modal.hide();
      this.$bvModal.hide("modal");
    },
    raBasesFiltered(comptence_id) {
      if (!comptence_id) return [];
      return this.ra_bases
        .filter((x) => x.competences.includes(comptence_id))
        .sort((a, b) => a.order - b.order);
    },
    askForUpdateCompetence(row_competence) {
      this.competence_in_proccess = row_competence;
      this.$refs.competence_modal.show();
    },
    competenceCognitiveLevel(cell_index, row) {
      let flag = false;
      if (cell_index < this.egressProfileCycleId.length - 1) {
        for (
          var i = cell_index;
          i < this.egressProfileCycleId.length - 1;
          i++
        ) {
          if (row[cell_index].cognitive_level > row[i + 1].cognitive_level) {
            flag = true;
          }
        }
      }
      return flag;
    },
    askForDeleteCompetence(id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar la asociación de esta instancia de "${this.$getVisibleNames(
          "mesh.competence",
          false,
          "Competencia"
        )} en el ${this.$getVisibleNames(
          "mesh.egressprofile",
          false,
          "Perfil de Egreso"
        )}"?`,
        text: `"¡Esta acción no se podrá revertir!"`,
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) this.deleteCompetence(id);
      });
    },
    deleteCompetence(id) {
      this.$store.dispatch(names.DELETE_PROFILE_COMPETENCE, id).then(() => {
        toast(
          String(
            this.$getVisibleNames("mesh.competence", false, "Competencia") +
              " Eliminada."
          )
        );
      });
    },
    competencesMatrix() {
      if (!this.egressProfile && !this.egressProfileCycleSort) return [];
      let max_level = 1;
      this.competenceList.forEach((element) => {
        if (element.order > max_level && element.cycle) {
          max_level = element.order;
        }
      });
      let matrix = [];
      if (this.egressProfileCycleSort.length > 0) {
        for (var i = 0; i < max_level; i++) {
          matrix[i] = [];
          for (var j = 0; j < this.egressProfileCycleSort.length; j++) {
            matrix[i][j] = {
              id: generateUniqueId(),
              egress_profile: this.egress_profile_id,
              action: "",
              content: "",
              condition: "",
              sentence: "",
              cycle: this.egressProfileCycleSort[j].id,
              // can_edit: null,
              order: i + 1,
              type: 1,
            };
          }
        }
        this.competenceList.forEach((competence) => {
          try {
            if (competence.cycle != null) {
              let cycle = this.findCycle(competence.cycle);
              if (cycle)
                matrix[competence.order - 1][cycle.order - 1] = competence;
              matrix[competence.order - 1].forEach((element) => {
                if (isNaN(element.id)) {
                  element.type = competence.type;
                }
              });
            }
          } catch (error) {
            console.log("Error contruyendo Matriz de competencias. ", error);
          }
        });
      } else {
        matrix = this.competenceList.filter((x) => x.cycle == null);
      }
      this.competences_matrix = matrix;
    },
    findCycle(id) {
      let c = null;
      this.cycles.forEach((cycle) => {
        if (cycle.id == id) {
          c = cycle;
          return cycle;
        }
      });
      return c;
    },
  },
  watch: {
    competenceList: function () {
      this.competencesMatrix();
    },
    "career.id": function () {
      if (this.faculties.findIndex((x) => x.id == this.career.faculty) == -1)
        this.$store.dispatch(names.FETCH_FACULTY, this.career.faculty);
    },
  },
  created() {
    // console.log("Matriz 1 created...");
    // if (
    //   this.egressProfiles.findIndex((x) => x.id == this.egress_profile_id) == -1
    // )
    this.$store
      .dispatch(names.FETCH_CYCLES, this.egress_profile_id)
      .then(() => {
        this.competencesMatrix();
      });
  },
  beforeDestroy() {
    this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
    this.$store.commit(names.MUTATE_SHOW_INTELLIGENCE_SIDEBAR, false);
    this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);
  },
  mounted() {
    this.$store.commit(names.MUTATE_SHOW_INTELLIGENCE_SIDEBAR, true);
    this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
      visible_intelligence_options: [1],
      disabled_selected_intelligence_option: true,
      disabled_selected_egress_profile: true,
    });
    this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
      egress_profile_id: this.egress_profile_id,
      intelligence_option: 1,
    });
    this.competencesMatrix();
  },
};
</script>

<style scoped>
.class-badge {
  width: 30px;
}
th {
  background-color: var(--primary-color) !important;
  color: var(--primary-font-color) !important;
}
table,
th,
td {
  border: 1px solid black;
  position: relative;
}
div.float-button {
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
}
p {
  font-size: 16px !important;
}
.caption {
  font-size: 17px;
}
.cognitive-level-tooltip {
  text-align: justify !important;
  padding-left: 4px;
}
.empty-text {
  background: rgb(245, 245, 245);
  margin: auto;
  padding: 0.2em 1em;
  width: 65vh;
  border-radius: 3px;
  font-weight: bold;
}
.pointer-class {
  cursor: pointer;
}
.button-wrap {
  display: flex;
}
.ra-competences-container {
  max-height: 500px;
  overflow-y: auto;
}
.info-icon {
  position: relative;
  vertical-align: top;
  height: 12px;
  width: 12px;
}
@media print {
  .badge {
    border: none;
  }
}
</style>